import { detectBrowser } from "@shared/Utils/client-info-utils";
import { IceFeedbackFormSteps } from "../Component/ice-feedback-component";
import { getAuthenticatedUserId } from "../ice-feedback-storage-utilities";
import { getStateFromFeedbackCookie } from "../ice-feedback-cookie-utilities";

export interface CreateFeedbackRequest {
  text?: string;
  liked?: boolean;
  browser?: string;
  clientVersion?: string;
  userAgent?: string;
  appInsightsUserId?: string;
  userId?: string;
  feedbackType?: string;
  orderStep?: string;
  pageUrl?: string;
}

export const sendFeedbackDetails = async (
  postMethod: "POST" | "PUT",
  feedbackFormStep: IceFeedbackFormSteps,
  feedbackVariantType: string,
  publicId: string,
  feedbackFormText?: string,
  liked?: boolean,
) => {
  const feedbackUrl = feedbackFormStep === IceFeedbackFormSteps.details ? `/api/feedback/${publicId}` : "/api/feedback";

  const postData: CreateFeedbackRequest = getPopupPostData(
    feedbackFormStep,
    feedbackVariantType,
    publicId,
    feedbackFormText,
    liked,
  );

  return await fetch(feedbackUrl, {
    method: postMethod,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(postData),
  });
};

function getPopupPostData(
  feedbackFormStep: IceFeedbackFormSteps,
  feedbackVariantType: string,
  publicId: string,
  feedbackFormText?: string,
  liked?: boolean,
) {
  if (feedbackFormStep === IceFeedbackFormSteps.prompt) {
    return createPromptPostData(liked, feedbackVariantType);
  }

  return createDetailsPutData(feedbackFormText, publicId);
}

function createPromptPostData(liked: boolean | undefined, feedbackVariantType: string) {
  const clientInfo = detectBrowser(navigator.userAgent);
  const userId = getAuthenticatedUserId();
  const feedbackCookieState = getStateFromFeedbackCookie();
  const pageUrl = window.location.pathname.split("?")[0];

  return {
    liked: liked,
    browser: clientInfo.name,
    userAgent: navigator.userAgent,
    clientVersion: clientInfo.version,
    feedbackType: feedbackVariantType,
    orderStep: feedbackCookieState?.orderStepState || "Abonnementsbygger",
    userId: userId || undefined,
    pageUrl: pageUrl,
  };
}

function createDetailsPutData(feedbackFormText: string | undefined, publicID: string) {
  return {
    publicId: publicID,
    text: feedbackFormText,
  };
}
