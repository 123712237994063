import { type FunctionalComponent, h } from "preact";
import { useId, useRef, useState } from "preact/hooks";
import { IceFeedbackFormSteps } from "../Component/ice-feedback-component";
import { shouldFormShowBasedOnFeedbackCookie } from "../ice-feedback-cookie-utilities";
import { IceFeedbackPopupContent } from "./components/content";
import { IceFeedbackPopupHeader } from "./components/header";

export const IceFeedbackPopup: FunctionalComponent<{ show: boolean }> = ({ show }: { show: boolean }) => {
  const [showComponent, setShowComponent] = useState<boolean>(shouldFormShowBasedOnFeedbackCookie() && show);
  const [userFeedbackPositive, setUserFeedbackPositive] = useState<boolean | undefined>(undefined);
  const [feedbackFormStep, setFeedbackFormStep] = useState<IceFeedbackFormSteps>(IceFeedbackFormSteps.prompt);

  const headerAccessibilityId = useId();
  const feedbackDialogRef = useRef<HTMLDivElement>(null);

  if (!showComponent) return;

  return (
    <div className={"ice-feedback ice-feedback--popup"} aria-atomic="true" role="dialog" ref={feedbackDialogRef}>
      <IceFeedbackPopupHeader
        feedbackFormStep={feedbackFormStep}
        setShowComponent={setShowComponent}
        setFeedbackFormStep={setFeedbackFormStep}
        userFeedbackPositive={userFeedbackPositive}
        feedbackDialogRef={feedbackDialogRef}
        headerAccessibilityId={headerAccessibilityId}
      />
      <IceFeedbackPopupContent
        feedbackFormStep={feedbackFormStep}
        setFeedbackFormStep={setFeedbackFormStep}
        setUserFeedbackPositive={setUserFeedbackPositive}
        userFeedbackPositive={userFeedbackPositive}
        headerAccessibilityId={headerAccessibilityId}
      />
    </div>
  );
};
