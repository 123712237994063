import { type FunctionalComponent, createRef, Fragment, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { setFeedbackCookieSubmitted } from "../../ice-feedback-cookie-utilities";
import { IceSvgByName } from "../../Component/components/form";
import clsx from "clsx";
import { sendFeedbackAnalyticsEventForPopup } from "@shared/Analytics/content/feedback-analytics";
import { IceButtonWithLoading } from "@shared/Components/IceButtons/IceButtonWithLoading";
import { sendFeedbackDetails } from "../../Shared/feedbackservices";
import { IceFeedbackFormSteps } from "../../Component/ice-feedback-component";

export interface IceFeedbackPopupFormProps {
  userFeedbackPositive: boolean | undefined;
  feedbackFormStep: IceFeedbackFormSteps;
  setFeedbackFormStep: (feedbackStep: number) => void;
  publicId: string;
}

export const IceFeedbackPopupForm: FunctionalComponent<IceFeedbackPopupFormProps> = ({
  userFeedbackPositive,
  feedbackFormStep,
  setFeedbackFormStep,
  publicId,
}: IceFeedbackPopupFormProps) => {
  const [feedbackFormText, setFeedbackFormText] = useState("");
  const [formState, setFormState] = useState<"initial" | "loading" | "disabled" | "error">("initial");
  const [initialFocus, setinitialFocus] = useState<boolean>(false);
  const textareaRef = createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (!textareaRef?.current || initialFocus) return;
    textareaRef.current.focus();
    setinitialFocus(true);
  }, [textareaRef]);

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    if (feedbackFormText.trim().length < 2) {
      formSubmittedSuccessfully();
      return;
    }

    try {
      setFormState("loading");
      const response = await sendFeedbackDetails("PUT", feedbackFormStep, "IceNo", publicId, feedbackFormText);

      if (response.ok) {
        formSubmittedSuccessfully();
      } else {
        setFormState("error");
      }
    } catch (error) {
      console.error(error);
      setFormState("error");
    }
  };

  const formSubmittedSuccessfully = () => {
    setFeedbackCookieSubmitted(true);
    setFeedbackFormStep(feedbackFormStep + 1);
    setFormState("initial");
    sendFeedbackAnalyticsEventForPopup(
      userFeedbackPositive ? "ja-kommentar_send" : "nei-kommentar_send",
      "feedback_question_2",
    );
  };

  const feedbackText = userFeedbackPositive ? (
    <Fragment>
      Så bra, takk for tilbakemeldingen! Vi ønsker å bli enda bedre, kom gjerne med innspill til oss i feltet
      nedenfor...
    </Fragment>
  ) : (
    <Fragment>Oida, ikke bra! Takk for at du sier fra. Kom gjerne med forslag til hvordan vi kan bli bedre...</Fragment>
  );

  return (
    <Fragment>
      <p className="ice-feedback__title" tabIndex={-1}>
        {feedbackText}
      </p>
      <form className="ice-feedback-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="feedbackFormText">Hva kan vi forbedre?</label>
          <textarea
            value={feedbackFormText}
            onChange={(e) => {
              setFeedbackFormText((e.target as HTMLTextAreaElement).value);
            }}
            type="text"
            name="feedbackFormText"
            id="feedbackFormText"
            className={"textarea"}
            ref={textareaRef}
            disabled={formState === "disabled" || formState === "loading"}
          />
        </div>
        <div className="ice-feedback-form__error" aria-label="feilmelding" aria-live="polite">
          {formState === "error" && <p>Feil ved sending, prøv igjen senere</p>}
        </div>
        <div className="ice-feedback-form__submit">
          <div>
            <IceButtonWithLoading type="submit" className="btn--feedback__form-submit" btnState={formState}>
              <div className={clsx("btn-content", formState === "loading" ? "visibility-hidden" : "")}>Send inn</div>
            </IceButtonWithLoading>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export const PopupFormSuccess: FunctionalComponent = () => {
  return (
    <div
      className="ice-feedback-form--success ice-feedback-form--popup"
      role="region"
      aria-labelledby="ice-form-popup-success"
    >
      <div className="checkmark-ok background--green-30 mb-2">
        <IceSvgByName name="ice-tick" />
      </div>

      <p id="ice-form-popup-success">Tilbakemelding mottatt. Tusen takk for dine innspill!</p>
    </div>
  );
};
