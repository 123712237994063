import { FunctionalComponent, Fragment, h } from "preact";
import { IceSvgByName } from "./form";
import { IceFeedbackFormSteps } from "../ice-feedback-component";
import { sendFeedbackAnalyticsEventForComponent } from "@shared/Analytics/content/feedback-analytics";
import { sendFeedbackDetails } from "../../Shared/feedbackservices";
import { Dispatch, type StateUpdater, useState } from "preact/hooks";
import { IceButtonWithLoading } from "@shared/Components/IceButtons/IceButtonWithLoading";

interface IceFeedbackPromptProps {
  feedbackFormStep: IceFeedbackFormSteps;
  setFeedbackFormStep: (setFeedbackStep: number) => void;
  setUserFeedbackPositive: (setUserFeedbackPositive: boolean) => void;
  userFeedbackPositive: boolean | undefined;
  publicId: string;
  setPublicId: Dispatch<StateUpdater<string>>;
}

export const IceFeedbackPrompt: FunctionalComponent<IceFeedbackPromptProps> = ({
  feedbackFormStep,
  setFeedbackFormStep,
  setUserFeedbackPositive,
  userFeedbackPositive,
  setPublicId,
  publicId,
}: IceFeedbackPromptProps) => {
  const [formSubmitLoading, setFormSubmitLoading] = useState<boolean>(false);
  const [formSubmitError, setFormSubmitError] = useState<boolean>(false);
  const [formState, setFormState] = useState<"initial" | "yes submitted" | "no submitted" | "error">("initial");

  const handleSubmit = async (e: Event) => {
    e.preventDefault();

    setFormState(userFeedbackPositive ? "yes submitted" : "no submitted");

    setFormSubmitError(false);

    try {
      setUserFeedbackPositive(true);

      setFormSubmitLoading(true);
      const response = await sendFeedbackDetails("POST", feedbackFormStep, "IceNo", publicId, "", userFeedbackPositive);
      const data = (await response.json()) as { publicId: string };

      if (response.ok) {
        setPublicId(data.publicId);
        setFeedbackFormStep(feedbackFormStep + 1);
        sendFeedbackAnalyticsEventForComponent(userFeedbackPositive ? "ja_send" : "nei_send", "feedback_question_1");

        setFormState("initial");
      } else {
        setFormSubmitError(true);
        setFormState("error");
      }
    } catch (error) {
      console.error(error);
      setFormSubmitError(true);
      setFormState("error");
    } finally {
      setFormSubmitLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="ice-feedback__title ice-feedback__title--text-center">Fant du det du lette etter?</div>
      <form
        className={`ice-feedback__block ${formSubmitLoading ? "ice-feedback-form--loading" : ""}`}
        onClick={handleSubmit}
      >
        <IceButtonWithLoading
          type="button"
          className={`btn--feedback btn-feedback__component`}
          onClick={() => {
            setUserFeedbackPositive(true);
          }}
          btnState={formState === "yes submitted" ? "loading" : formState === "no submitted" ? "disabled" : "initial"}
        >
          <IceSvgByName name={"ice-thumbs-up"} />
          Ja
        </IceButtonWithLoading>
        <IceButtonWithLoading
          type="button"
          className={`btn--feedback btn-feedback__component`}
          onClick={() => {
            setUserFeedbackPositive(false);
          }}
          btnState={formState === "no submitted" ? "loading" : formState === "yes submitted" ? "disabled" : "initial"}
        >
          <IceSvgByName name={"ice-thumbs-down"} />
          Nei
        </IceButtonWithLoading>
        <div className="ice-feedback-form__error" aria-label="feilmelding" aria-live="polite">
          {formSubmitError && <p>Feil ved sending, prøv igjen senere</p>}
        </div>
      </form>
    </Fragment>
  );
};
